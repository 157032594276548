import { VaporProvider } from '@vapor/ui';
import Head from 'next/head';
import React from 'react';
import '../styles/reset.css';
import '../styles/variables.css';
import '../styles/global.css';

function MyApp({ Component, pageProps }) {
  return (
    <VaporProvider ssr>
      <Head>
        <link rel="stylesheet" href="https://fonts.onhighloop.com/inter/index.css" />

        <title>Highloop</title>
      </Head>

      <Component {...pageProps} />
    </VaporProvider>
  );
}

export default MyApp;
